<template>
  <section v-for="(item, itemIndex) in items" :key="itemIndex" class="image-text-group"
    :class="getSectionClass(itemIndex, itemIndex)">
    <div class="slide-class">
      <sliderCompare :leftImage="item.after" :rightImage="item.before" />
      <a href="https://www.facebook.com/groups/705362347626022" style="border: none;color:grey;font-size: 10px;">Image
        source: Facebook. If there is any infringement,
        please contact us to remove.</a>
    </div>
    <!-- <img :src="item.image" :alt="item.alt" /> -->
    <div class="image-text-section">
      <h3 style="margin: 1vw;">{{ $t(item.title) }}</h3>
      <p style="margin: 1vw;color: thistle; font-weight: 300;">{{ $t(item.text) }}</p>
    </div>
  </section>
</template>

<script setup>
import sliderCompare from "@/components/sliderCompare.vue";
import { defineProps } from 'vue';

// 定义 props
defineProps({
  items: {
    type: Array,
    required: true
  }
});

// 获取 section 的 class
const getSectionClass = (itemIndex) => {
  if (itemIndex % 2 === 0) {
    return 'left-image';
  } else {
    return 'right-image';
  }
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

header {
  text-align: center;
  margin-bottom: 20px;
}

main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.slide-class {
  width: 40%;
  height: 20vw;
  border-radius: 12px;
  border: gray solid 1px;
  margin-right: 20px;
  align-items: center;
}

.image-text-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.left-image {
  flex-direction: row;
}

.right-image {
  flex-direction: row-reverse;
}

.image-text-group sliderCompare {
  max-width: 50%;
  height: auto;
}

.image-text-section {
  flex: 1;
  padding: 60px;
  width: 85%;
  height: auto;
  font-size: 28px;
  color: aliceblue;
  text-wrap: wrap;
}



/* 小屏幕设备上的样式 */
@media (max-width: 600px) {
  .image-text-group {
    flex-direction: column !important;
    margin-bottom: 5vw;
  }

  .image-text-section {
    padding: 1vw;
    font-size: 24px;
  }

  .slide-class {
    width: 95%;
    height: 80vw;
    margin-right: 0px;
  }

  .image-text-group sliderCompare {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
</style>