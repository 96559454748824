<template>
	<footer class="footer">
		<div class="footer-content">
			<div class="language-selector">
				<select v-model="selectedLanguage" @change="changeLanguage">
					<option v-for="lang in languages" :key="lang.value" :value="lang.value">{{ lang.label }}</option>
				</select>
			</div>
			<div class="links">
				<router-link to="/blogs">{{ $t('Blog') }}</router-link>
				<a :href="termsofServiceurl" target="_blank">{{ $t('Terms-of-Service') }}</a>
				<a :href="privacypolicyurl" target="_blank">{{ $t('privacy-policy') }}</a>
			</div>
		</div>
	</footer>
</template>

<script setup>
import { ref, watch } from 'vue'
// import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { useUserStore } from '@/store/index';
import { useRouter, useRoute } from 'vue-router'
import i18n from '@/i18n'; // 引入 i18n 实例
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();
const router = useRouter()
const route = useRoute()
const selectedLanguage = ref('en')

// const { t, locale } = useI18n()
// onMounted(() => {
// 	// 初始化 locale
// 	console.log("route.params.locale",route.params.locale,locale.value )
//   if (route.params.locale) {
// 		locale.value = route.params.locale;
// 		selectedLanguage.value = route.params.locale;
//   }
// });
// locale.value = route.params.locale ? route.params.locale:localStorage.getItem('userLanguage');
const userStore = useUserStore();
// const ogUrl = computed(() => `https://www.imagefaceswap.com/${i18n.global.locale}`)
console.log("init locale is=====", i18n.global.locale, route.params.locale, locale.value)


// onBeforeRouteUpdate((to, from, next) => {
//   console.log("Route updated with locale:", to.params.locale);
//   next();
// });



// const currentLanguage = ref(route.params.locale);

// 监听路由变化，更新当前语言
watch(() => route.params.locale, (newLocale) => {
	// console.log("best locale watch", route.params.locale,newLocale);
	locale.value = newLocale
	localStorage.setItem('userLanguage', newLocale);
	updateChangeLanguage(route.params.locale)
	userStore.updataLang(newLocale)
	console.log("selectedLanguagedddww222ddddd", selectedLanguage.value, userStore.lang)
	if (userStore.lang != null) {
		console.log("selectedLanguageddddd", selectedLanguage.value, userStore.lang)
		selectedLanguage.value = locale.value;
	}
});

const getLang = (locale) => {
	switch (locale) {
		case 'zhCN':
			return 'zh-CN';
		case 'zhTW':
			return 'zh-TW';
		default:
			return locale;
	}
};
// const userLanguages = ref(navigator.language || navigator.userLanguage);

// if (userLanguages.value.startsWith('zh-CN')) {
//   userLanguages.value = 'zhCN'; // 简体中文
// } else if (userLanguages.value.startsWith('zh-TW') || userLanguages.value.startsWith('zh-HK') || userLanguages.value.startsWith('zh-MO')) {
//   userLanguages.value = 'zhTW'; // 繁体中文
// } else {
//   userLanguages.value = userLanguages.value.split("-")[0];
// }

// userLanguages.value = route.params.locale ? route.params.locale : userLanguages.value;
// localStorage.setItem('userLanguage', userLanguages.value);
// userStore.updataLang(userLanguages.value);
// watchEffect(() => {

// const userLanguage = localStorage.getItem('userLanguage');

// } else { 
//   // 如果有 userLanguage，则使用它
// 	userLanguages.value = userLanguages;
// 	selectedLanguage.value = userLanguages
// 	locale.value = userLanguages
// }
// router.push({ params: { locale: userLanguages.value } });
// console.log("user lang change footer", userStore.lang);
// locale.value = userStore.lang;
// console.log("userStore.lang===",userStore.lang)
// selectedLanguage.value = userStore.lang;
// updateChangeLanguage(userStore.lang);
// router.push({ params: { locale: userStore.lang } });

// });

// useHead({
// 	title: t('title_index'),
// 	htmlAttrs: {
// 		lang: locale.value,
// 	},

// 	meta: [
// 		{ name: 'description', content: t('description_index') },
// 		{ name: 'keywords', content: t('keywords_index') },
// 		{ property: 'og:title', content: t('title_index') },
// 		{ property: 'og:description', content: t('description_index') },
// 		{ property: 'og:url', content: ogUrl.value },
// 		{ name: 'twitter:title', content: t('title_index') },
// 		{ name: 'twitter:description', content: t('description_index') },
// 		{ name: 'twitter:url', content: ogUrl.value },
// 		{ name: 'lang', content: locale.value }
// 	],
// 	link: [

// 		{ rel: 'alternate', hreflang: 'x-default', href: 'https://www.imagefaceswap.com' },
// 		{ rel: 'alternate', hreflang: 'en', href: 'https://www.imagefaceswap.com' },
// 		{ rel: 'alternate', hreflang: 'en', href: 'https://www.imagefaceswap.com/en' },
// 		{ rel: 'alternate', hreflang: 'ar', href: 'https://www.imagefaceswap.com/ar' },
// 		{ rel: 'alternate', hreflang: 'de', href: 'https://www.imagefaceswap.com/de' },
// 		{ rel: 'alternate', hreflang: 'es', href: 'https://www.imagefaceswap.com/es' },
// 		{ rel: 'alternate', hreflang: 'fr', href: 'https://www.imagefaceswap.com/fr' },
// 		{ rel: 'alternate', hreflang: 'hi', href: 'https://www.imagefaceswap.com/hi' },
// 		{ rel: 'alternate', hreflang: 'it', href: 'https://www.imagefaceswap.com/it' },
// 		{ rel: 'alternate', hreflang: 'ja', href: 'https://www.imagefaceswap.com/ja' },
// 		{ rel: 'alternate', hreflang: 'ko', href: 'https://www.imagefaceswap.com/ko' },
// 		{ rel: 'alternate', hreflang: 'nl', href: 'https://www.imagefaceswap.com/nl' },
// 		{ rel: 'alternate', hreflang: 'pt', href: 'https://www.imagefaceswap.com/pt' },
// 		{ rel: 'alternate', hreflang: 'ru', href: 'https://www.imagefaceswap.com/ru' },
// 		{ rel: 'alternate', hreflang: 'tr', href: 'https://www.imagefaceswap.com/tr' },
// 		{ rel: 'alternate', hreflang: 'vi', href: 'https://www.imagefaceswap.com/vi' },
// 		{ rel: 'alternate', hreflang: 'zh-CN', href: 'https://www.imagefaceswap.com/zhCN' },
// 		{ rel: 'alternate', hreflang: 'zh-TW', href: 'https://www.imagefaceswap.com/zhTW' }
// 	]

// })

// 切换语言
function updateChangeLanguage(lang) {
	locale.value = lang
	router.push({ params: { locale: lang } })
	// 更新元数据
	useHead({
		// 	title: t('title_index'),
		htmlAttrs: {
			lang: getLang(lang),
		},
	}
	)

	// 	meta: [
	// 		{ name: 'description', content: t('description_index') },
	// 		{ name: 'keywords', content: t('keywords_index') },
	// 		{ property: 'og:title', content: t('title_index') },
	// 		{ property: 'og:description', content: t('description_index') },
	// 		{ property: 'og:url', content: ogUrl.value },
	// 		{ name: 'twitter:title', content: t('title_index') },
	// 		{ name: 'twitter:description', content: t('description_index') },
	// 		{ name: 'twitter:url', content: ogUrl.value },
	// 		{ name: 'lang', content: locale.value }
	// 	],
	// 	link: [
	// 		{ rel: 'alternate', hreflang: 'x-default', href: 'https://www.imagefaceswap.com' },
	// 		{ rel: 'alternate', hreflang: 'en', href: 'https://www.imagefaceswap.com' },
	// 		{ rel: 'alternate', hreflang: 'en', href: 'https://www.imagefaceswap.com/en' },
	// 		{ rel: 'alternate', hreflang: 'ar', href: 'https://www.imagefaceswap.com/ar' },
	// 		{ rel: 'alternate', hreflang: 'de', href: 'https://www.imagefaceswap.com/de' },
	// 		{ rel: 'alternate', hreflang: 'es', href: 'https://www.imagefaceswap.com/es' },
	// 		{ rel: 'alternate', hreflang: 'fr', href: 'https://www.imagefaceswap.com/fr' },
	// 		{ rel: 'alternate', hreflang: 'hi', href: 'https://www.imagefaceswap.com/hi' },
	// 		{ rel: 'alternate', hreflang: 'it', href: 'https://www.imagefaceswap.com/it' },
	// 		{ rel: 'alternate', hreflang: 'ja', href: 'https://www.imagefaceswap.com/ja' },
	// 		{ rel: 'alternate', hreflang: 'ko', href: 'https://www.imagefaceswap.com/ko' },
	// 		{ rel: 'alternate', hreflang: 'nl', href: 'https://www.imagefaceswap.com/nl' },
	// 		{ rel: 'alternate', hreflang: 'pt', href: 'https://www.imagefaceswap.com/pt' },
	// 		{ rel: 'alternate', hreflang: 'ru', href: 'https://www.imagefaceswap.com/ru' },
	// 		{ rel: 'alternate', hreflang: 'tr', href: 'https://www.imagefaceswap.com/tr' },
	// 		{ rel: 'alternate', hreflang: 'vi', href: 'https://www.imagefaceswap.com/vi' },
	// 		{ rel: 'alternate', hreflang: 'zh-CN', href: 'https://www.imagefaceswap.com/zhCN' },
	// 		{ rel: 'alternate', hreflang: 'zh-TW', href: 'https://www.imagefaceswap.com/zhTW' }
	// 	]

	// })
}
// 定义语言列表
const languages = ref([
	{ value: 'ar', label: 'العربية' },
	{ value: 'de', label: 'Deutsch' },
	{ value: 'en', label: 'English' },
	{ value: 'es', label: 'Español' },
	{ value: 'fr', label: 'Français' },
	{ value: 'hi', label: 'हिन्दी' },
	{ value: 'it', label: 'Italiano' },
	{ value: 'ja', label: '日本語' },
	{ value: 'ko', label: '한국어' },
	{ value: 'nl', label: 'Nederlands' },
	{ value: 'pt', label: 'Português' },
	{ value: 'ru', label: 'Русский' },
	{ value: 'tr', label: 'Türkçe' },
	{ value: 'vi', label: 'Tiếng Việt' },
	{ value: 'zhCN', label: '中文（简体）' },
	{ value: 'zhTW', label: '中文（繁體）' }
]);

const changeLanguage = () => {
	// 这里可以添加语言切换的逻辑，比如更新页面内容或重定向到相应的语言页面
	updateChangeLanguage(selectedLanguage.value)
	userStore.updataLang(selectedLanguage.value)
	console.log('Selected language:', selectedLanguage.value);
};
const termsofServiceurl = `${window.location.origin}/terms-of-service.html`
const privacypolicyurl = `${window.location.origin}/privacy-policy.html`
// const blogurl = `${window.location.origin}/best-ai-boyfriend-tools.html`

// const goToBlogList = () => {
//   router.push("/blogs");
// };


</script>

<style scoped>
.footer {
	background-color: #333;
	color: #fff;
	padding: 20px 0;
	text-align: center;
}

.footer-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1200px;
	margin: 0 auto;
}

.language-selector select {
	padding: 5px;
	font-size: 16px;
}

.links a {
	color: #fff;
	margin: 0 10px;
	text-decoration: none;
}

.links a:hover {
	text-decoration: underline;
}

@media (max-width: 768px) {
	.footer-content {
		flex-direction: column;
		gap: 10px;
	}

	.links {
		flex-direction: column;
		align-items: center;
	}
}
</style>