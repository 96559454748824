<template>
  <div class="feature-container">
    <div class="feature-modules" ref="featureModules">
      <div v-for="(module, index) in modules" :key="index" class="feature-module">
        <!-- 功能模块内容 -->
        <div class="module-content">
          <h2>{{ module.title }}</h2>
          <p>{{ module.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';

gsap.registerPlugin(Draggable);

const featureModules = ref(null);
const modules = ref([
  { title: '功能模块1', description: '这是功能模块1的描述' },
  { title: '功能模块2', description: '这是功能模块2的描述' },
  { title: '功能模块3', description: '这是功能模块3的描述' },
]);

onMounted(() => {
  // 初始化动画
  gsap.from('.feature-module', {
    scale: 0,
    opacity: 0,
    stagger: 0.2,
    duration: 0.5,
    ease: 'power2.out',
    delay: 0.5
  });

  // 初始化拖拽功能
  Draggable.create(featureModules.value, {
    type: 'x',
    bounds: { minX: -window.innerWidth * 2, maxX: 0 },
    edgeResistance: 0.65,
    inertia: true,
  });
});
</script>

<style scoped>
.feature-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-modules {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.feature-module {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.module-content {
  text-align: center;
}
</style>