<template>
  <div class="sidebar">
  </div>
  <div class="processing-steps">
    <div v-for="(step, index) in steps" :key="index" class="step">
      <div>
        <h3>{{ $t(step) }}</h3>
      </div>
      <div class="loading-spinner">
        <svg viewBox="0 0 50 50" class="spinner">
          <circle cx="25" cy="25" r="20" fill="none" stroke-width="5" stroke="blue" />
        </svg>
      </div>
    </div>
    <h3 class="h3-word">Subscribe and save 80% of time</h3>
  </div>
</template>

<script setup>
import { ref } from 'vue';
// import { useI18n } from 'vue-i18n'
// const { t } = useI18n();

const steps = ref([
  'deblur',
  'remove_noise',
  'face_restoration',
  'enhance_detail'
]);
</script>

<style scoped>
.sidebar {
  width: 90%;
  height: 89%;
  position: absolute;
  background-color: #f8f8f8;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 999;
  backdrop-filter: blur(15px);
  opacity: 0.8;
}

.processing-steps {
  padding: 20px;
  width: 60%;
  height: auto;
  margin-bottom: 20px;
  position: absolute;
  z-index: 99999;
  align-items: center;
  /* justify-content: center; */
}

.step {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: 1rem;
  font-size: 16px;
  color: #726d6d;
  /* width: 40%; */
  height: 5vw;
  align-items: center;
  justify-content: space-between;

}

.loading-spinner {
  text-align: center;
}

.spinner {
  animation: rotate 2s linear infinite;
  width: 5vw;
  height: 5vw;
}

.spinner circle {
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@keyframes gradientBreathing {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0.8;
  }

  50% {
    opacity: 1;
  }
}

.h3-word {
  font-size: 2em;
  background: linear-gradient(45deg, #eb1414, #feb47b, #76b852);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientBreathing 2s ease infinite, fadeInOut 1s ease-in-out infinite;
}

@media screen and (max-width: 720px) {
  .step {
    font-size: 3vw;
    height: 5vw;
  }

  .sidebar {
    height: 86%;
  }

  .spinner {
    width: 5vw;
    height: 5vw;
  }
}
</style>