<template>
  <div
    style="color: #f5f2f4;display: flex;font-size:2rem;font-weight: 400;width: 100%;height: auto;align-items: center;justify-content: center;flex-direction: column;padding-top: 20px;text-align: center;">
    <div>
      {{ $t('FAQS') }}
    </div>
    <div style="font-size: 1rem;">
      {{ $t('concat_us', { email: 'support@imagefaceswap.com' }) }}
    </div>
  </div>
  <div class="faq_style">
    <div v-for="(item, index) in faqList" :key="index" class="faq-item">
      <div class="question" @click="toggleAnswer(index)">
        <span style="width: 70%;">{{ index + 1 }}. {{ $t(item.question) }}</span>
        <span class="arrow" :class="{ 'arrow-down': !showAnswer[index], 'arrow-up': showAnswer[index] }">
          &#9654;
        </span>
      </div>
      <div v-if="showAnswer[index]" class="answer">
        {{ $t(item.answer) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';

// 使用 defineProps 定义 faqList 属性
const props = defineProps({
  faqList: {
    type: Array,
    required: true,
  },
});

// 初始化 showAnswer 数组
const showAnswer = ref(props.faqList.map(() => false));

// 切换答案显示状态的函数
const toggleAnswer = (index) => {
  showAnswer.value[index] = !showAnswer.value[index];
};
</script>

<style scoped>
.faq_style {
  width: 80%;
  height: auto;
  margin-left: 10%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: #f5f2f4
}

.faq-item {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.question {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.question span {
  font-weight: bold;
}

.arrow {
  font-size: 12px;
  transition: transform 0.3s;
}

.arrow-down {
  transform: rotate(0deg);
}

.arrow-up {
  transform: rotate(90deg);
}

.answer {
  padding: 10px 0;
  color: #a8a4a4;
}

/* 小屏幕尺寸自适应 */
@media (max-width: 600px) {
  .faq {
    padding: 10px;
  }

  .faq-item {
    margin-bottom: 10px;
  }

  .question {
    padding: 5px 0;
  }

  .answer {
    padding: 5px 0;
  }
}
</style>