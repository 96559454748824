<template>
  <div class="image-comparison" ref="box">
    <img class="base-image" :src="leftImage" alt="" ref="baseImage" />
    <div class="cover-image" ref="coverImage" :style="coverImageStyle">
      <img :src="rightImage" alt="" ref="rightImage" />
    </div>
    <div class="slider" ref="slider" @mousedown="startDrag" @mousemove="drag" @mouseup="stopDrag" @mouseleave="stopDrag"
      @touchstart="startDrag" @touchmove="drag" @touchend="stopDrag" @touchcancel="stopDrag">
      <span class="tag-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-code"
          viewBox="0 0 16 16">
          <path
            d="M5.854 4.854a.5.5 0 1 0-.708-.708l-3.5 3.5a.5.5 0 0 0 0 .708l3.5 3.5a.5.5 0 0 0 .708-.708L2.707 8l3.147-3.146zm4.292 0a.5.5 0 0 1 .708-.708l3.5 3.5a.5.5 0 0 1 0 .708l-3.5 3.5a.5.5 0 0 1-.708-.708L13.293 8l-3.147-3.146z" />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageComparison",
  props: {
    leftImage: {
      type: String,
      required: true
    },
    rightImage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      offsetX: 0,
      sliderPosition: 50,
      rafId: null
    };
  },
  computed: {
    coverImageStyle() {
      return {
        clipPath: `inset(0 ${100 - this.sliderPosition}% 0 0)`
      };
    }
  },
  methods: {
    startDrag(event) {
      this.isDragging = true;
      const clientX = event.touches ? event.touches[0].clientX : event.clientX;
      this.offsetX = clientX - this.$refs.slider.offsetLeft;
      window.addEventListener("mousemove", this.drag);
      window.addEventListener("mouseup", this.stopDrag);
      window.addEventListener("touchmove", this.drag);
      window.addEventListener("touchend", this.stopDrag);
      window.addEventListener("touchcancel", this.stopDrag);

      // 禁用过渡效果
      this.$refs.slider.style.transition = 'none';
    },
    drag(event) {
      if (this.isDragging) {
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        const newSliderPosition = clientX - this.offsetX;
        const containerWidth = this.$refs.box.offsetWidth;
        this.sliderPosition = (newSliderPosition / containerWidth) * 100;

        // 使用 requestAnimationFrame 来平滑更新
        if (this.rafId) {
          cancelAnimationFrame(this.rafId);
        }
        this.rafId = requestAnimationFrame(() => {
          this.$refs.slider.style.left = `${this.sliderPosition}%`;
        });
      }
    },
    stopDrag() {
      this.isDragging = false;
      window.removeEventListener("mousemove", this.drag);
      window.removeEventListener("mouseup", this.stopDrag);
      window.removeEventListener("touchmove", this.drag);
      window.removeEventListener("touchend", this.stopDrag);
      window.removeEventListener("touchcancel", this.stopDrag);
      if (this.rafId) {
        cancelAnimationFrame(this.rafId);
      }

      // 恢复过渡效果
      this.$refs.slider.style.transition = 'left 0.05s ease';
    }
  }
};
</script>

<style>
.image-comparison {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.image-comparison * {
  user-select: none;
}

.image-comparison .slider {
  position: absolute;
  left: calc(50% - 4px);
  width: 3px;
  height: 100%;
  cursor: col-resize;
  z-index: 20;
  background: rgb(248, 247, 247);
  transition: left 0.s ease;
}

.image-comparison .slider .tag-icon {
  position: absolute;
  top: 80%;
  left: 50%;
  border: 2px solid rgba(244, 241, 241, 0.5);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-comparison .slider .tag-icon svg {
  width: 3rem;
  height: 3rem;
  fill: white;
}

.image-comparison .cover-image,
.image-comparison .base-image {
  position: absolute;
  left: 0;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.image-comparison .cover-image {
  left: 0;
  width: 100%;
  object-fit: contain;
  z-index: 20;
}

.image-comparison img {
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 720px) {
  .image-comparison .slider .tag-icon {

    width: 2rem;
    height: 2rem;

  }

  .image-comparison .slider .tag-icon svg {
    width: 2rem;
    height: 2rem;
  }
}
</style>