<!-- // eslint-disable-next-line vue/multi-word-component-names -->
<script setup>
import { ref, computed, onMounted, watchEffect, onUnmounted } from 'vue';
// import { useHead } from '@vueuse/head'
// import LayerOut from '@/components/LayerOut.vue'
// import footerPage from '@/components/footerPage.vue'
// import { useStore } from 'vuex'
// import axios from 'axios';
import { useUserStore, activeDivStore } from '@/store/index';
import axiosInstance from '@/axios.config'; // 导入配置好的 Axios 实例
import { fetchImage } from '@/utils/fetchImage';
import ImageViewer from './ImageViewer.vue';
import Compressor from 'compressorjs';
import sliderCompare from "@/components/sliderCompare.vue";
import content_faqs from '@/components/content_faqs.vue';
import ReviewCard from '@/components/ReviewCard.vue';
import { useHead } from '@vueuse/head'
// import { useRoute } from 'vue-router';
import { closeImageViewer, imageViewerVisible, currentImageUrl, downloadImage } from '@/imageViewerHandler';
// import ContentFaceSwap from '@/components/ContentFaceSwap.vue';
import { useI18n } from 'vue-i18n'
const userStore = useUserStore();
const useActiveDivStore = activeDivStore()
const isHoveringA = ref(false);
const enhancer = ref(false)
const { t, locale } = useI18n();

// 控制放大图显示原图的弹窗
// const ogUrl = ref("https://www.imagefaceswap.com/${locale.value}/photo_enhancer")
watchEffect(() => {
	// console.log(userStore.lang)
	const ogUrl = computed(() => `https://www.imagefaceswap.com/${userStore.lang}/photo-restoration`)
	console.log("监听到变化enhancer=", userStore.showSubcrib)
	locale.value = userStore.lang
	useHead({
		title: t('title_enhancer'),
		htmlAttrs: {
			lang: locale.value,
		},

		meta: [
			{ name: "robots", content: "index,follow" },
			{ name: "googlebot", content: "index,follow" },
			{ name: 'description', content: t('description_restore') },
			{ name: 'keywords', content: t('keywords_restore') },
			{ property: 'og:title', content: t('title_restore') },
			{ property: 'og:description', content: t('description_restore') },
			{ property: 'og:url', content: ogUrl.value },
			{ name: 'twitter:title', content: t('title_restore') },
			{ name: 'twitter:description', content: t('description_restore') },
			{ name: 'twitter:url', content: ogUrl.value },
			{ name: 'lang', content: locale.value }
		],
		link: [

			{ rel: 'alternate', hreflang: 'x-default', href: 'https://www.imagefaceswap.com/photo-restoration' },
			{ rel: 'alternate', hreflang: 'en', href: 'https://www.imagefaceswap.com/photo-restoration' },
			{ rel: 'alternate', hreflang: 'en', href: 'https://www.imagefaceswap.com/en/photo-restoration' },
			{ rel: 'alternate', hreflang: 'ar', href: 'https://www.imagefaceswap.com/ar/photo-restoration' },
			{ rel: 'alternate', hreflang: 'de', href: 'https://www.imagefaceswap.com/de/photo-restoration' },
			{ rel: 'alternate', hreflang: 'es', href: 'https://www.imagefaceswap.com/es/photo-restoration' },
			{ rel: 'alternate', hreflang: 'fr', href: 'https://www.imagefaceswap.com/fr/photo-restoration' },
			{ rel: 'alternate', hreflang: 'hi', href: 'https://www.imagefaceswap.com/hi/photo-restoration' },
			{ rel: 'alternate', hreflang: 'it', href: 'https://www.imagefaceswap.com/it/photo-restoration' },
			{ rel: 'alternate', hreflang: 'ja', href: 'https://www.imagefaceswap.com/ja/photo-restoration' },
			{ rel: 'alternate', hreflang: 'ko', href: 'https://www.imagefaceswap.com/ko/photo-restoration' },
			{ rel: 'alternate', hreflang: 'nl', href: 'https://www.imagefaceswap.com/nl/photo-restoration' },
			{ rel: 'alternate', hreflang: 'pt', href: 'https://www.imagefaceswap.com/pt/photo-restoration' },
			{ rel: 'alternate', hreflang: 'ru', href: 'https://www.imagefaceswap.com/ru/photo-restoration' },
			{ rel: 'alternate', hreflang: 'tr', href: 'https://www.imagefaceswap.com/tr/photo-restoration' },
			{ rel: 'alternate', hreflang: 'vi', href: 'https://www.imagefaceswap.com/vi/photo-restoration' },
			{ rel: 'alternate', hreflang: 'zh-CN', href: 'https://www.imagefaceswap.com/zhCN/photo-restoration' },
			{ rel: 'alternate', hreflang: 'zh-TW', href: 'https://www.imagefaceswap.com/zhTW/photo-restoration' }
		]

	})
})
// 语言支持




// const handleImageClick = (event) => {
// 	const imageUrl = event.target.src;
// 	showImageViewer(imageUrl);
// };

const isLoggedIn = userStore.isLoggedIn;
onMounted(() => {
	if (!isLoggedIn.value) {
		userStore.updateNoneLoginUserInfo()
	} else {
		userStore.fetchCredits()
	}
});


const user = computed(() => userStore.isLoggedIn ? userStore.user : userStore.guestUser);

const userCredits = computed(() => userStore.isLoggedIn ? userStore.userCredit : userStore.guestCredit);

const loading = ref(false);


const fileInput = ref(null);
const contentBox = ref(null)
// const userFace = ref(null)
// const template = ref([])
const isImageUploaded = ref(false);
const activeDiv = ref('swap_face');
const user_curent_task_id = ref(null)


// 上传图片
const imageA = ref(null)
const imageUrlA = ref(null)
const show_downLoad = ref(false)
const isUploading = ref(false)
const mylib_result_urls = computed(() => userStore.mylib_result);
// 控制显示哪张图片的变量
// const showImageB = ref(false);
const result_url = ref(null)
// const toggleImages = () => {
//   result_url.value = !result_url.value;
// };

// 控制按钮是否禁用
const isDisabled = ref(false);


watchEffect(() => {
	// console.log(useActiveDivStore.activeDiv)
	if (useActiveDivStore.activeDiv) {
		activeDiv.value = useActiveDivStore.activeDiv
	}
	if (activeDiv.value === 'my_result' || userStore.mylib_result) {
		// console.log("监控数据",userStore.getters.getList)
		mylib_result_urls.value = userStore.getters.getList
	}

	if (result_url.value) {
		isDisabled.value = true
	}

})

const openFileInput = () => {
	if (!result_url.value) {
		fileInput.value.click()
		show_downLoad.value = false
	}
}

const StartNew = () => {
	fileInput.value.click()
}

const handleFaceDrop = (event) => {
	const file = event.dataTransfer.files[0];
	if (file) {
		console.log("进来了=====1")
		show_downLoad.value = false
		isImageUploaded.value = true; // 上传图片后改变按钮 B 的颜色
		result_url.value = null
		isUploading.value = true
		isHoveringA.value = false;
		imageA.value = null
		imageUrlA.value = null
		// 使用 compressorjs 进行图片压缩
		new Compressor(file, {
			quality: 0.6, // 压缩质量，0.6 表示 60% 的质量
			maxWidth: 1024, // 最大宽度
			maxHeight: 1024, // 最大高度
			success(compressedFile) {
				// 上传压缩后的文件
				uploadFile(compressedFile, 'your face').then((img_url) => {
					fetchImage(img_url).then((url) => {
						imageUrlA.value = url;
						imageA.value = url;
						console.log("imageUrlA", imageUrlA.value);
						// console.log("imageA", imageA.value);
						isUploading.value = false; // 上传完成后将 isUploading 设置为 false
					});
				});
			},
			error(err) {
				console.error('Compression error:', err.message);
				isUploading.value = false; // 压缩失败后将 isUploading 设置为 false
			},
		});
	} else {
		isUploading.value = false; // 如果没有文件选择，将 isUploading 设置为 false
		// imageA.value = null
		// imageUrlA.value = null
	}
};

let pollingInterval = null;

const handleFileChange = async (event) => {
	const file = event.target.files[0]
	if (file) {
		console.log("进来了=====2")
		show_downLoad.value = false
		isImageUploaded.value = true; // 上传图片后改变按钮 B 的颜色
		result_url.value = null
		isHoveringA.value = false;
		imageA.value = null
		imageUrlA.value = null
		isUploading.value = true
		// 使用 compressorjs 进行图片压缩
		new Compressor(file, {
			quality: 0.6, // 压缩质量，0.6 表示 60% 的质量
			maxWidth: 1024, // 最大宽度
			maxHeight: 1024, // 最大高度
			success(compressedFile) {
				// 上传压缩后的文件
				uploadFile(compressedFile, 'your face').then((img_url) => {
					fetchImage(img_url).then((url) => {
						imageUrlA.value = url;
						imageA.value = url;
						console.log("handleFileChange imageUrlA", imageUrlA.value);
						// console.log("imageA", imageA.value);
						isUploading.value = false; // 上传完成后将 isUploading 设置为 false
					});
				});
			},
			error(err) {
				console.error('Compression error:', err.message);
				isUploading.value = false; // 压缩失败后将 isUploading 设置为 false
			},
		});
	} else {
		isUploading.value = false; // 如果没有文件选择，将 isUploading 设置为 false
		// imageA.value = null
		// imageUrlA.value = null
	}
}

const uploadFile = async (file, type) => {
	const formData = new FormData()
	formData.append('file', file)
	try {
		const response = await axiosInstance.post('/api/swap_face/upload', formData)
		if (response.status == 200) {
			isUploading.value = false
			return response.data.img_url
		} else {
			alert(type + " image upload faild,please check your picture and reload upload again!")
		}
	} catch (error) {
		alert('image upload faild,please check your picture', error)
		console.error('image upload faild,please check your picture:', error)
		isUploading.value = false
		// return null
	}
}

const pollTaskResult = () => {
	// if (pollingInterval) {
	// 	console.log('轮询任务已存在，不再启动新的轮询任务');
	// 	return;
	// }
	pollingInterval = setInterval(async () => {
		try {
			const response = await axiosInstance.post(`/api/swap_face/result`, {
				'task_id': user_curent_task_id.value, 'isLogin': userStore.isLoggedIn
			});
			if (response.status === 200 || response.status === 400) {
				// console.log("result",response.data)
				const result = response.data;
				if (result.status === 'completed') {
					// 任务完成，获取下载链接
					result_url.value = result.download_url;
					clearInterval(pollingInterval); // 停止轮询
					loading.value = false; // 隐藏动效
					show_downLoad.value = true
					enhancer.value = false
				}
			}
		} catch (error) {
			clearInterval(pollingInterval); // 停止轮询
			loading.value = false; // 隐藏动效
		}
	}, 5000 * 10); // 每 5 秒轮询一次
};


onUnmounted(() => {
	clearInterval(pollingInterval); // 组件卸载时停止轮询
});

const buttonText = ref(null)
watchEffect(() => {
	if (show_downLoad.value) {
		buttonText.value = "Download"
	} else if (loading.value) {
		buttonText.value = "Run In Background"
	} else {
		buttonText.value = "Restore Now!"
	}
});

const handleClick = async () => {
	console.log("handleClick=====进来了3")
	if (loading.value) {
		// alert('生成任务已在后台进行，请稍候...');
		if (pollingInterval) {
			clearInterval(pollingInterval);
			// console.log('轮询已停止');
		}
		loading.value = false;
		imageUrlA.value = null
		imageA.value = null
		isImageUploaded.value = false
		show_downLoad.value = false
		return;
	}

	if (!imageUrlA.value) {
		alert('please upload image');
		return;
	}
	loading.value = true;
	try {
		if (show_downLoad.value) {
			loading.value = false;
			await downloadImage(result_url.value);
		} else {
			if (userCredits.value < 30) {
				// console.log("need buy credists")
				userStore.showSubcrib = true
				loading.value = false
				return
			} else { await generate(30); }
		}
	} catch (error) {
		console.error(error);
		// loading.value = false;
	} finally {
		// loading.value = false;
	}
};

const generate = async (needCredit) => {
	// 通过变量控制是否每过 10s轮询一次/用户点击完成通知我
	result_url.value = null
	if (!imageUrlA.value) {
		alert("please upload face image")
	}

	loading.value = true; // 显示动效
	enhancer.value = true
	if (imageUrlA.value) {
		try {
			const response = await axiosInstance.post('/api/swap_face/create', {
				udid: user.value.udid,
				image_url: imageUrlA.value,
				template_url: "",
				task_type: "photo_restoration",
				task_id: Date.now(),
				is_custom: false,
				isLogin: userStore.isLoggedIn
			})
			if (response.status == 200) {

				// 任务创建成功
				console.log("generate task creat ok", imageUrlA.value)
				userStore.consumeCredits(needCredit)
				user_curent_task_id.value = response.data.task_id
				// 开始轮询任务结果
				pollTaskResult();

			} else {
				alert("task faild", response.data.error)
			}
		} catch (error) {
			console.error('image process erro:', error)
		}
	}
}

const upscale_after = "https://d2uvllf6n93h2s.cloudfront.net/6d7c157e-9802-49a2-9930-d071e9ff7744.webp"
const upscale_before = "https://d2uvllf6n93h2s.cloudfront.net/b1092a11-9cea-4ff7-8515-b486c01b1feb.webp"

// 配置图片和文字信息
const items = ref([
	{
		after: "https://d2uvllf6n93h2s.cloudfront.net/6d7c157e-9802-49a2-9930-d071e9ff7744.webp",
		before: "https://d2uvllf6n93h2s.cloudfront.net/b377b55f-1859-4331-add2-457e6ee252bf.webp",
		title: 'photo_restore.title1',
		text: 'photo_restore.text1',
	},
	{
		after: "https://d2uvllf6n93h2s.cloudfront.net/a04c2672-2e6c-480b-be97-af267cf4b2b4.webp",
		before: "https://d2uvllf6n93h2s.cloudfront.net/e84faf42-7fb0-4d9c-81aa-066df38a87d4.webp",
		title: 'photo_restore.title2',
		text: 'photo_restore.text2',
	},
	{
		after: "https://d2uvllf6n93h2s.cloudfront.net/54bf1a1a-8542-4405-9070-37b5f83c0dc9.webp",
		before: "https://d2uvllf6n93h2s.cloudfront.net/ed58b3e9-d595-44ec-8285-c12db14c6eef.webp",
		title: 'photo_restore.title3',
		text: 'photo_restore.text3',
	},
	{
		after: "https://d2uvllf6n93h2s.cloudfront.net/71071d2d-9ba0-4524-85e3-d40c192e18da.webp",
		before: "https://d2uvllf6n93h2s.cloudfront.net/ddfe3703-af24-4727-b1c3-0d97ca54c170.webp",
		title: 'photo_restore.title4',
		text: 'photo_restore.text4',
	},
	{
		after: "https://d2uvllf6n93h2s.cloudfront.net/70ef670d-8da6-4e75-960b-722e834eb217.webp",
		before: "https://d2uvllf6n93h2s.cloudfront.net/03ec8442-1336-4db9-a918-4f8a2feec0ea.webp",
		title: 'photo_restore.title5',
		text: 'photo_restore.text5',
	}
]);


const restore_faqList = [
	{
		question: "restore_faqs.question1",
		answer: "restore_faqs.answer1",
	},
	{
		question: "restore_faqs.question2",
		answer: "restore_faqs.answer2",
	},
	{
		question: "restore_faqs.question3",
		answer: "restore_faqs.answer3",
	},
	{
		question: "restore_faqs.question4",
		answer: "restore_faqs.answer4",
	},
	{
		question: "restore_faqs.question5",
		answer: "restore_faqs.answer5",
	}
];


const restore_views = [
	{
		image: 'https://d2uvllf6n93h2s.cloudfront.net/76c89227-f7ad-4bfa-820d-e9e07c265c4d.webp',
		stars: 5,
		name: "restore_views.name1",
		comment: "restore_views.comment1",
	},
	{
		image: 'https://d2uvllf6n93h2s.cloudfront.net/a06dc153-c0f7-4054-a935-f6f58905dc0e.webp',
		stars: 5,
		name: "restore_views.name2",
		comment: "restore_views.comment2",
	},
	{
		image: 'https://d2uvllf6n93h2s.cloudfront.net/136410f1-f473-4f15-be36-dcebe215be62.webp',
		stars: 5,
		name: "restore_views.name3",
		comment: "restore_views.comment3",
	},
];

const screenWidth = ref(window.innerWidth);
const isMobile = ref(screenWidth.value <= 768);

const updateScreenSize = () => {
	screenWidth.value = window.innerWidth;
	isMobile.value = screenWidth.value <= 768;
};

onMounted(() => {
	window.addEventListener('resize', updateScreenSize);
});

onUnmounted(() => {
	window.removeEventListener('resize', updateScreenSize);
});

</script>

<script>
export default {
	'name': 'photo-restoration'
}
</script>


<template>
	<div class="container">
		<ImageViewer :imageUrl="currentImageUrl" :visible="imageViewerVisible" @close="closeImageViewer" />
		<div class="content_box" ref="contentBox">
			<div class="swap_box content_box_height content_weight">
				<div class="row_content row_content_margin">
					<div>
						<h2 class="h2">{{ $t('content_title_restore') }}</h2>
						<span style="margin-bottom: 1rem;">{{ $t('content_description_restore') }}</span>
					</div>
					<div
						style="display: flex; flex-direction: column; width: 90%; height: 100%; align-items: center; justify-content: center;">
						<button :disabled="isDisabled"
							:class="{ 'col_content': true, 'row_content_margin_child': true, 'hover': isHoveringA }"
							style="border: none;align-items: center;justify-content:center" @dranengver.prevent
							@drop.prevent="handleFaceDrop" @dragenter="isHoveringA = true" @dragleave="isHoveringA = false">
							<div
								style="width: 80%;height: 100%;border-radius: 12px;border: gray solid 1px;margin-left: 20px;margin-right: 20px;display: flex;">
								<imageProcessoring v-if="loading" />
								<input class="row_content row_content_margin_child" style="display: none;" type="file" ref="fileInput"
									@change="handleFileChange" accept="image/*" />
								<div v-if="isUploading && !imageUrlA" class="loading-spinner" style="margin-top: 48%;margin-left: 48%;">
								</div>
								<div v-else :class="{ 'input_type': true }" @click="openFileInput">
									<!-- <img v-if="result_url" :src="result_url" alt="Image B" class="button-image"> -->
									<sliderCompare v-if="result_url" :leftImage="result_url" :rightImage="imageA" />
									<img v-else-if="imageA" :src="imageA" alt="Image A" class="button-image">
									<img v-else src="../assets/upload-img.png" alt="" class="upload-img">
								</div>
							</div>
							<div v-if="!loading & !isMobile & !result_url"
								style="width: 80%;height: 100%;border-radius: 12px;border: gray solid 1px;margin-right: 20px;">
								<sliderCompare v-if="!result_url" :leftImage="upscale_after" :rightImage="upscale_before" />
							</div>
						</button>
						<h3 style="color: darkgray;line-height: 1px;">30 credits | 2 mins</h3>
						<div
							style="display: flex;width: 100%;height: auto; flex-direction: row;margin: 20px;align-items: center;justify-content: center;">
							<button v-if="result_url" :class="{ 'click_generate_upload': true, 'click_generate': true }"
								@click="StartNew">
								<input class="row_content row_content_margin_child" style="display: none;margin-right: 20px;"
									type="file" ref="fileInput" @change="handleFileChange" accept="image/*" />
								Start New
							</button>
							<button v-if="activeDiv !== 'my_result'"
								:class="{ 'click_generate_upload': isImageUploaded, 'click_generate': !isImageUploaded }"
								@click="handleClick">
								<span v-if="!loading">{{ buttonText }}</span>
								<span v-else class="loading-spinner"></span>
								<span v-if="loading">{{ buttonText }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div>
			<ImageContent :items="items" />
		</div>
		<ReviewCard :reviews="restore_views" />
		<content_faqs :faqList="restore_faqList" />
		<!-- <ContentFaceSwap /> -->
		<!-- <button class="back-to-top" @click="scrollToTop">Back to Top</button> -->
	</div>
</template>

<style scoped lang="css">
.image-container {
	position: relative;
	display: inline-block;
}

.magnifier {
	position: relative;
	bottom: 10px;
	right: 10px;
	top: 20vw;
	cursor: pointer;
	z-index: 10000;
	left: -11vw;
	width: 25%;
	height: 25%;
}

.magnifier-icon {
	width: 30px;
	height: 30px;
	margin-top: 5vw;
	margin-left: 5vw;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
}

.modal img {
	max-width: 90%;
	max-height: 90%;
	cursor: pointer;
}

.question-item {
	width: 85%;
	/* text-align: left; */
	margin-left: 0px auto;
	margin-bottom: 10px;
	color: #1f1f1f;
	/* border: #a7b0ba; */
	padding: 10px;
	border-radius: 5px;

}

.question-title {
	display: flex;
	align-items: center;
	background: #f5f2f4;
	;
	width: 90%;
	padding: 20px;
	height: 1.3rem;
	cursor: pointer;
	font-weight: bold;
	border-radius: 10px;
}

.question-answer {
	color: #b8b3b3;
	margin-top: 10px;
	padding-left: 20px;
}

.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}

.popup-content {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	position: relative;
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 20px;
	cursor: pointer;
}

form {
	display: flex;
	flex-direction: column;
}

label {
	margin-top: 10px;
}

input {
	padding: 10px;
	margin-top: 5px;
	/* border: 1px solid #ccc; */
	border-radius: 3px;
}

button[type="submit"] {
	margin-top: 20px;
	padding: 10px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 3px;
	cursor: pointer;
}

button[type="submit"]:hover {
	background-color: #0056b3;
}

.blur-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(5px);
	z-index: 999;
}


.content {
	padding: 20px;
}

/* .container {
display: flex;
align-items: center;
padding: 20px;
} */

.container {
	/*路径*/

	left: 0px;
	top: 0px;
	width: auto;
	height: auto;
	background: url("../assets/background.png");
	overflow: hidden;

}

.content_box_height {
	height: 600px;
	/* border: #de11e5 solid 1px; */
}

.topcontainer {

	/* border: #007bff solid 1px; */
	overflow: hidden;
	width: auto;
	height: 60px;
	background: url("../assets/daohanglan.png");
	/* overflow: hidden; */
	/* padding: auto; */
	display: flex;
	/* 如果需要水平居中也可以添加： */
	align-items: center;
	/* 设置高度，例如： */
	/* height: 100vh; */
}

.linktype {
	/* border: #f462c8 solid 1px; */
	display: flex;
	/* justify-content:center; */
	/* 如果需要水平居中也可以添加： */
	align-items: center;
	width: 70%;
	margin-left: 0px;
	/* 设置高度，例如： */
	/* height: 100vh; */
}


.content_box {
	/* float: left; */
	/* border: #f5f2f4 solid 1px; */
	display: flex;
	/* align-items: center; */
	overflow: hidden;
	/* position: relative; */

}

.upload-img {
	width: 30%;
	height: auto;
	/* margin-top: 9vw */
}

.content_weight {
	margin-left: 70px;
}

.swap_box {
	/*路径*/
	/* left: 183px;
top: 220px; */
	position: relative;
	display: flex;
	margin-top: 0px;
	margin-right: 70px;
	height: auto;
	align-items: center;

	flex-direction: column;
	justify-content: center;
	/* text-align: center; */
	/* justify-content:flex-end; */
	width: 100%;
	/* opacity: 1; */
	/* border: #ef24c0 solid 1px; */
	background: rgb(249, 250, 250);
}

.input_type {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.h2 {
	margin-top: 1rem;
}

.col_content {
	position: relative;
	display: flex;
	/* align-items: center; */
	margin-left: 20px;
	justify-content: center;
	flex-direction: row;
	/* border: #ef24c0 solid 1px; */
}

.col_content2 {
	position: relative;
	display: flex;
	/* align-items:center; */

	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.row_content_back {
	background: rgb(247, 242, 242);
	/*路径*/
}

.row_content {
	position: relative;
	display: flex;
	/* align-items: center;
justify-content:center; */
	flex-direction: column;
	/* border: hsl(167, 67%, 67%) solid 1px; */
}

.row_content_margin {

	/* margin-left: 10px;
margin-top: 10px; */
	width: 100%;
	height: 100%;
	align-items: center;
	text-align: center;
	margin-left: 20px;
	/* justify-content: center; */
}

.row_content_margin_child {

	margin-left: 10px;
	margin-top: 2rem;
	margin-right: 10px;
	position: relative;
	width: 100%;
	height: 30vw;
	object-fit: cover;
	border-radius: 12px;
	/* border: #b07ee2 solid 1px; */
	/* overflow: hidden; */
	/* background-size: 100% 100%; */
}

.row_content_margin_child.hover {
	background-color: #838385;
	/* 更深的灰色背景 */
	border-color: #888;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
	/* 更深的阴影效果 */
}

.button-image {
	margin-top: 2rem;
	max-width: 80%;
	max-height: 80%;
	object-fit: cover;

}

.select_type {
	width: 80%;
	height: 5rem;
	/* border: #976af2 solid 1px; */
	align-items: center;
	justify-content: center;
	padding: 10px 15px;
	margin-top: 20px;
	/* background-color: rgb(81, 79, 84); */
	display: flex;
	text-align: center;
	flex-direction: column;
	background: linear-gradient(135deg, #ffffff, #f8f8f8);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: background-color 0.3s, transform 0.2s;
	cursor: pointer;
	border-radius: 10px;
	/* transform: perspective(100px) rotateX(5deg); */
}

.select_type :hover {
	/* background: linear-gradient(135deg, #f0f0f0, #e0e0e0); */
	/* transform: translateY(-2px); */
}

.select_type .selected {
	background: linear-gradient(135deg, #333, #555);
	color: white;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

}

.select_type_box {
	width: 22rem;
	height: 90%;
	margin-top: -1rem;
	margin-right: 10px;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: rgb(190, 227, 244);
	border-radius: 10px;
}

.buton_select {
	width: 5rem;
	height: 2rem;
	display: flex;
	margin-left: 2px;
	margin-right: 2px;

	/* margin:auto; */
	justify-content: center;
	align-items: center;
	/* float: left; */
	font-size: 1rem;
	/* padding: 10px; */
	word-wrap: break-word;
	border-radius: 10px;
	background: linear-gradient(135deg, #8ec5fc, #e0c3fc);
	color: white;
	/* justify-content: center; */

	/* border: #d71559 solid 1px; */
}

.opotion_box {
	width: 100%;
	height: 10rem;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: row;
	/* overflow: scroll; */
	/* overflow-x: scroll */
	/* transform: perspective(100px) rotateX(5deg); */

	/* margin: 20px; */
}

.type_name {
	width: 100%;
	font-weight: bold;
	height: 1.5rem;
	margin-bottom: 10px;
	text-transform: uppercase;
	color: #272424;
	font-size: 1em;
	letter-spacing: 1px;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
	pointer-events: none;
	/* 禁止 hover 动效 */
	background: #d0d0d0;
	/* padding: 5px 10px; */
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	/* transform: perspective(100px) rotateX(5deg); */
}

.gender_select {

	width: 10rem;
	height: 2rem;
	border: #007bff;
	display: flex;
	margin: 0.5rem;
	align-items: center;
	float: left;
	word-wrap: break-word;
	border-radius: 8%;
	color: #976af2;
	transition: color 0.3s ease, transform 0.3s ease;
	justify-content: center;

}

.click_generate {

	/*路径*/

	/* left: 383px;
top: 843px; */
	margin-top: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30%;
	height: 4vw;
	font: 100 24px/1.5 PingFang SC, sans-serif;
	color: #f5f2f4;
	opacity: 1;
	background: rgb(158, 156, 156);
	border-radius: 20px;
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	cursor: pointer;
	margin-bottom: 1rem;

}

.click_generate_upload {

	/*路径*/

	/* left: 383px;
top: 843px; */
	margin-top: 2vw;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30%;
	height: 4vw;
	font: 300 24px/1.5 PingFang SC, sans-serif;
	color: #f5f2f4;
	;
	opacity: 1;
	background: rgb(16, 127, 238);
	border-radius: 20px;
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	cursor: pointer;
	margin-bottom: 1rem;

}

/* .loading-spinner {
border: 4px solid rgba(0, 0, 0, 0.1);
width: 36px;
height: 36px;
border-radius: 50%;f
border-top-color: #3498db;
animation: spin 1s linear infinite;
} */
.loading-spinner {
	width: 36px;
	height: 36px;
	background-image: url('../assets/loading2.png');
	/* 替换为你的图片路径 */
	background-size: cover;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.img {

	/* max-width: 90%;
height: 90%; */
	/* 可以根据需要调整高度 */
	/* background-image: url("https://img.mobiuspace.com/image/aigc/95698695c820af829243c7d0eeb4579d.webp"); */
	/* background-size: 80%; */
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	/* 保持图片原始宽高比，覆盖整个容器 */
	/* border: #007bff solid 1px; */



	/* overflow: hidden; */

}

.upload_img {
	/* width: 60%;
/* height: 90%; */

	background-size: 60% auto;
	background-position: center;
	background-repeat: no-repeat;
	object-fit: cover;
	background-image: url("../assets/upload-img.png");
	background-color: transparent;
	border: none;
	cursor: pointer;

}

.upload_img:hover {
	color: #976af2;
	/* 悬浮时背景颜色变化 */
	transition: color 0.3s ease, transform 0.3s ease;
	transform: translateY(-5px);
	/* 悬浮时向上移动 */
	/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
	/* 悬浮时添加阴影 */
}



.tempalte_box {
	/*路径*/

	/* left: 1273px;
top: 97px; */
	position: relative;
	display: flex;
	margin-left: auto;
	margin-top: 40px;
	margin-right: 30px;
	/* margin-bottom: 900; */
	width: 30%;
	height: auto;
	opacity: 1;
	/* border: #7610f2 solid 1px; */
	background: rgba(21, 14, 38, 1);
}

.swap_word1 {
	/*free face swap online*/

	/* left: 435px;
top: 241px; */
	margin-top: -3vw;
	/* margin-bottom: 0.5vw; */
	width: 100%;
	height: 7rem;
	text-align: center;
	/* opacity: 1; */
	/* display: flex; */
	/* position: absolute; */
	/** 文本1 */
	font-size: 2.5rem;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 1.5rem;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	/* border:#007bff solid 1px */
}

.swap_word2 {
	/*experience fun or real swaps: select sin*/

	/* left: 290px;
top: 329px;
width: 810px;
height: 29px; */
	/* position: absolute; */
	display: flex;
	justify-content: center;
	opacity: 1;
	/** 文本1 */
	margin-top: -2rem;
	display: flex;
	font-size: calc(20px + 0.5vw);
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 3rem;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	padding: 5px;
	/* border: #ef24c0 solid 1px; */
}

.font_name3 {
	/*face swap*/

	/* left: 462px;
top: 373px; */
	/* width: 118px;
height: 35px; */
	opacity: 1;
	position: relative;
	/** 文本1 */
	margin-top: 3px;
	margin-left: 30px;
	margin-right: 30px;
	display: flex;
	font-size: 24px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 35px;
	color: rgba(112, 112, 112, 1);
	text-align: right;
	vertical-align: top;
	/* border: #00ff40 solid 1px; */
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.font_name3.isActive::before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 3px;
	background: linear-gradient(to right, #1f1f1f, #007bff);
	border-radius: 8px;
	bottom: -10px
}

.font3_box {
	/* border: #00ff40 solid 1px; */
	margin-top: 0.5rem;
	margin-left: 30px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	/* margin-top: 20px;
margin-left: 30px; */
	/* justify-content: space-between; */
	/* float:inline-start; */
	width: 90%;
	height: auto;
}

.template_img {
	position: relative;
	display: flex;
	gap: 15px;
	flex-wrap: wrap;
	/* border: #00ff40 solid 1px; */
	/* margin-bottom: 10px; */
}

.template_img img {
	position: relative;
	width: calc(50% - 20px);
	height: auto;
	justify-content: space-between;
	margin: 5px;
	/* margin-top: 50px; */
	/* margin-bottom: 10px; */
	/* width: 20%; */
	object-fit: cover;
	border-radius: 10px;
	/* 50%宽度减去间距 */
	box-sizing: border-box;
	align-items: center;

}

.selected-image {
	border-color: #b07ee2 solid 2px;
}

.template_type {

	display: flex;
	width: 100%;
	height: auto;
	position: relative;
	margin-top: 80px;
	display: flex;
	background: rgba(44, 39, 56, 1);
	/* align-items:center; */
	flex-direction: column;
	/* border: #ef24c0 solid 1px; */
}

.template_type_select {

	/*sporty*/

	/* left: 26px;
top: 5.5px;
width: 50px;
height: 23px;
opacity: 1; */
	/** 文本1 */
	display: flex;
	position: relative;
	margin: 5px;
	border-radius: 8%;
	/* padding: auto; */
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 23px;
	color: #f5f2f4;
	/* border: none; */
	/* background: seashell; */
	vertical-align: top;
	background-color: #01080f;
	cursor: pointer;

	/*路径*/

	/* left: 0px;
top: 0px; */
	width: 102px;
	height: 35px;
	/* opacity: 1; */
	/* border: 1px solid rgba(72, 52, 212, 1); */
	background-color: transparent;
	border: none;
	cursor: pointer;

}

/* .template_type_select:hover { */
/* background-color: rgb(246, 246, 248); */
/* 悬停时背景颜色变为深蓝色 */
/* } */


.template_type_select.isActive {
	/*路径*/

	/* left: 0px;
top: 0px; */
	/* content: ''; */
	/* position: absolute; */
	/* left: 0; */
	/* width: 102px;
height: 35px; */
	/* opacity: 1; */
	background: rgba(104, 109, 224, 1);
	box-shadow: 0px 1px 2px rgba(20, 14, 62, 0.25);
	z-index: 2;
}

.upload_template {
	position: absolute;
	display: flex;
	margin-top: 20px;
	margin-bottom: 50px;
	margin-left: 20px;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 90%;
	height: 30px;

	/*路径*/
	/* width: 506px; */
	/* height: 40px; */
	/* opacity: 1; */
	background: rgba(204, 204, 204, 0.3);

	/** 文本1 */
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 38px;
	color: #f5f2f4;
	;
	vertical-align: top;
	/* border: #007bff solid 1px; */
	cursor: pointer;
	/* background-color: transparent; */
	border: none;
	/* cursor: pointer; */
}

.img_doc {
	width: auto;
	height: 40vw;
	display: flex;
	/* position: relative; */
	/* border: #00ff40 solid 1px; */
	/* align-items: center;
justify-content: center; */
	flex-direction: row;

}

.img_doc1_box {
	/*face-swap-and-demonstrate-the-ability-to-generate-various-different-faces-of-a-man 1*/
	display: flex;
	margin-top: 5vw;
	overflow: hidden;
	width: 50%;
	height: 419px;
}


.img_doc1_box_box {
	/*create infinite faces with al face swap technology*/

	/* left: 915px;
top: 1168px; */
	/* margin-top: 50%;
margin-left: 50%; */
	/* margin: 10px; */
	/* float: left; */
	position: relative;
	display: flex;
	width: 50%;
	margin-top: 5vw;
	height: auto;
	opacity: 1;
	/* border: #ff0055 solid 1px; */
	flex-direction: column;
	align-items: center;
	justify-content: center;

}

.img_doc1_box_doc1 {
	/*create infinite faces with al face swap technology*/

	/* left: 915px;
top: 1168px; */
	margin-top: 0vw;
	margin-left: 1vw;
	position: absolute;
	display: flex;
	width: 90%;
	height: auto;
	opacity: 1;
	/** 文本1 */
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0px;
	color: #f5f2f4;
	;
	vertical-align: top;
	/* border: #007bff solid 1px; */
}

.img_doc1_box_doc2 {

	/*ai face swap has surged in popularity as a dynamic photo trend. its advanced algorithms accurately detect and track
facial features, allowing users to effortlessly create realistic face swaps. Pook ai face swapper is one of the best
free online tools for face swapping, you can use it to seamlessly swap face in any photo and enjoy endless fun while
engaging with realistic face swap photos.*/

	margin-top: 8vw;
	margin-left: 1vw;
	width: 90%;
	height: auto;
	opacity: 1;
	position: absolute;
	display: flex;
	/** 文本1 */
	font-size: 26px;
	font-weight: 400;
	letter-spacing: 0px;
	/* line-height: 12px; */
	color: #f5f2f4;
	;
	vertical-align: top;
	/* border: #f462c8 solid 1px; */
}

.swap_boten {
	display: flex;
	position: absolute;
	margin-top: 20vw;
	margin-left: 10vw;
	width: 358px;
	height: 92px;
	opacity: 1;
	background: rgba(72, 52, 212, 1);
	/* border: #00ff40 solid 1px; */
	cursor: pointer;
	border: none;
}

.swap_boten.text {

	width: 178px;
	height: 38px;
	opacity: 1;
	/** 文本1 */
	font-size: 26px;
	font-weight: 400;
	align-items: center;
	justify-content: center;
	letter-spacing: 0px;
	line-height: 38px;
	color: #f5f2f4;
	;
	text-align: center;
	vertical-align: top;
}

.doc2_box {
	/* left: 500px;
top: 1886px; */
	/* margin-left: 5vw; */

	display: flex;
	align-items: center;
	justify-content: center;
	/* margin-top: 2vw; */
	margin: 2vw auto;
	width: 90%;
	height: 160px;
	opacity: 1;
	font-size: 40px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 60px;
	color: #f5f2f4;
	;
	/* background: #d7e3f0; */
	vertical-align: top;
	text-align: center;
	/* 文字向左对齐 */
	word-wrap: break-word;
	/* 允许长单词或 URL 地址换行到下一行 */
	/* border: #7610f2 solid 1px;; */
}

.row_doc_box_base {

	display: flex;
	position: relative;
	float: left;
	margin-top: 10px;
	margin: 10px auto;
	width: auto;
	height: auto;
	/* opacity: 1; */
	flex-direction: row;
	/* overflow: hidden; */
	flex-wrap: wrap;
	/* 允许换行 */
	justify-content: space-between;
	/* 项目之间的间距均匀分布 */
	/* border: #00ff40 solid 1px; */
	overflow: hidden;
}

.col_doc_box_base {

	display: flex;
	position: relative;
	float: left;
	margin-top: 10px;
	width: auto;
	height: 40vw;
	opacity: 1;
	flex-direction: column;
	/* overflow: hidden; */
	/* flex-wrap: wrap; */
	/* 允许换行 */
	/* justify-content: space-between; */
	/* 项目之间的间距均匀分布 */
	/* border: #00ff40 solid 1px; */
	overflow: hidden;
}

/* 第一块图文 */
.doc3_box_1 {

	/*rectangle 13*/


	/* position: relative; */
	/* margin-top: 10px; */
	/* margin-left: 5vw; */
	margin: 10px auto;
	/* left: 0px;
top: 100px; */
	width: 31%;
	height: 40%;
	/* opacity: 1; */
	/* background: rgba(40, 25, 65, 1); */
	/* border: #ef24c0 solid 1px; */
}

.doc3_box_1_image {
	max-width: 65%;
	/* 图片最大宽度为容器的100% */
	max-height: 65%;
	/* 图片最大高度为容器的100% */
	display: block;
	/* 将图片转换为块级元素 */
	margin: 0 auto;
	/* 水平居中 */
	object-fit: cover;
	overflow: hidden;
}

.doc3_box_1_text_box {

	/* opacity: 1; */
	display: flex;
	/* line-height: 1vw; */
	flex-direction: column;
	padding-left: 1vw;
	padding-right: 1vw;
	/* padding-bottom: 1vw; */
	padding-top: 0.15vw;
	border-radius: 10px;
	background: rgba(255, 255, 255, 1);
	height: 11vw;
}




.doc3_box_1_text {


	/*ai face swap has surged in popularity*/

	/* left: 83px;
top: 2399px;
width: 474px;
height: 40px;
opacity: 1; */
	/** 文本1 */

	font-size: 1.4em;
	font-weight: 350;
	letter-spacing: 0px;
	line-height: auto;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	/* width: 90%; */
	/* 文本最大宽度为容器的100% */
	/* display: block; */
	/* 将文本转换为块级元素 */
	/* margin: 0 auto; */
	/* 水平居中 */
	/* text-align: left; */
	/* 文字向左对齐 */
	text-wrap: wrap;
	/* 允许长单词或 URL 地址换行到下一行 */
	/* font-size: 24px; */
	/* color: #f5f2f4; */
	/* border: #ff0055 solid 1px;; */
}


.doc3_box_1_text2 {

	/** 文本1 */
	font-size: 1em;
	font-weight: 00;
	letter-spacing: 0px;
	line-height: auto;
	color: rgba(0, 0, 0, 1);
	vertical-align: top;
	text-wrap: wrap;

}

.swap_button_style {
	width: 8em;
	height: 2em;
	opacity: 1;
	font-size: 1.3em;
	font-weight: 100;
	color: #f5f2f4;
	background: rgba(72, 52, 212, 1);
	/* border: #00ff40 solid 1px; */
	cursor: pointer;
	border-radius: 15px;
	border: none;
}

.box_center {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
}

/* 第3块图文 */
.box_text_base {

	margin-left: 5vw;
	/* padding: 2vw; */
	font-size: 1.4em;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(250, 247, 247);
	vertical-align: top;
	text-align: center;
	/* width: 90%; */
	/* 文本最大宽度为容器的100% */
	/* display: block; */
	/* 将文本转换为块级元素 */
	/* margin: 0 auto; */
	/* 水平居中 */
	/* text-align: left; */
	/* 文字向左对齐 */
	word-wrap: break-word;

}


.box_text_base2 {

	/** 文本1 */
	margin-top: 2vw;
	margin-left: 5vw;
	font-size: 1em;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(248, 245, 245);
	vertical-align: top;
	word-wrap: break-word;

}

.box_img_base {

	/* max-width: 50%; */
	/* 图片最大宽度为容器的100% */
	float: left;
	max-width: 90%;
	max-height: auto;
	/* 图片最大高度为容器的100% */
	display: block;
	/* 将图片转换为块级元素 */
	margin: 0 auto;
	/* 水平居中 */
	object-fit: cover;
}

.fdvfd {
	/*rectangle 14*/

	/* left: 1104px;
top: 3571px; */
	/* margin-top: 7vw; */
	margin: 6vw auto;
	width: 616px;
	height: 453px;
	opacity: 1;
	background: rgba(217, 217, 217, 0);
	border: 2px solid rgba(79, 142, 217, 1);
	/* overflow: hidden; */
	/* object-fit:auto; */
}


.buy_back_box {
	/*rectangle 23*/

	/* left: 109px;
top: 8298px; */
	display: flex;
	/* position: absolute; */
	width: 30%;
	height: auto;
	opacity: 1;
	border-radius: 8px;
	background: #c2cad2;
	flex-direction: column;
	border-radius: 12px;
	align-items: center;
	/* justify-content: center; */
	/* background: rgba(255, 255, 255, 1); */
	/* border: #007bff solid 1px; */
}

.buy_back_box_text {
	/*free and quaility face swaps*/

	/* left: 119px;
top: 8322px; */
	/* width: 470px;
height: 46px; */
	display: flex;
	/* justify-items: center; */
	align-items: center;
	/* justify-content: space-between; */
	/* margin: 2px auto; */
	width: 100%;
	height: 60px;
	border-radius: 12px;
	/** 文本1 */
	font-size: 1.5rem;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(11, 11, 11);
	vertical-align: top;
	background-color: #f5f2f4;

}

.buy_back_box_text2 {
	/*free and quaility face swaps*/
	/* margin: 2px auto; */
	/** 文本1 */
	display: flex;
	/* margin-top: 50px; */
	width: 80%;
	height: auto;
	padding: 1em;
	font-size: 1rem;
	font-weight: 300;
	letter-spacing: 0px;
	line-height: auto;
	color: rgb(25, 24, 24);
	vertical-align: top;
	flex-wrap: wrap;
	text-align: left;
	align-items: center;
	justify-content: center;
	border-radius: 12px;

	/* overflow: scroll; */

}

.buy_back_box_text img {

	padding: 10px;

	width: 50px;
	/* 设置图片的宽度 */
	height: 50px;
	/* 设置图片的高度 */
	border-radius: 50%;
	/* 将图片裁剪成圆形 */
	object-fit: cover;
	/* 确保图片内容被裁剪成圆形 */
	margin-left: 10px;
}

.stars {
	/*star 1*/
	/* position: absolute; */

	margin: 1vw auto;
	/* border: #00ff40 solid 1px; */
	/* width: auto; */
	/* height: 30px; */
	/* display: flex; */
	margin-top: 10px;
	width: 90%;
	height: auto;
	text-align: center;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	/* object-fit: cover; */
}

.faq {

	/*1.can i face swap pnline for free*/
	display: flex;
	align-items: center;
	justify-content: left;
	margin: 5vw auto;
	width: 80%;
	height: 46px;
	opacity: 1;
	/** 文本1 */
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 0px;
	line-height: 46px;
	color: rgba(255, 255, 255, 1);
	vertical-align: top;
	/* border: #a694a0 solid 1px; */
}

.conect_us {

	display: flex;
	align-items: center;
	justify-content: left;
	/* margin: 30px auto; */
	flex-direction: row;
	color: #f5f2f4;
	letter-spacing: 2px;
	word-spacing: 2px;
	border-top: #3e3f3e solid 2px;

}

.connect_us_box {
	display: flex;
	margin: 10px;
}

/* 小屏幕设备 */
@media screen and (max-width: 720px) {

	.content_box {
		/* float: left; */
		/* border: #f5f2f4 solid 1px; */
		display: flex;
		/* align-items: center; */
		overflow: hidden;
		/* position: relative; */
		flex-direction: column;

	}

	.content_weight {
		margin-left: 30px;
	}

	.swap_box {
		margin-top: auto;
		margin-right: 0px;
		margin-left: 0%;
		width: auto;
		height: auto;
		overflow: hidden;
		/* border: #de11e5 solid 1px; */
	}

	.col_content {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		/* border: #ef24c0 solid 1px; */
	}

	.col_content2 {
		position: relative;
		display: flex;
		/* align-items:center; */

		flex-direction: column;
		/* border: #ef24c0 solid 1px; */
	}

	.row_content_back {
		background: rgb(247, 242, 242);
		/*路径*/
	}

	.row_content {
		position: relative;
		display: flex;
		/* align-items: center;
justify-content:center; */
		flex-direction: column;
		/* border: hsl(167, 67%, 67%) solid 1px; */
	}

	.row_content_margin {

		margin-left: 10px;
		margin-top: 10px;
		width: 100%;
		height: 50%;
	}

	.row_content_margin_child {

		margin-left: 10px;
		margin-top: 10px;
		margin-right: 20px;
		position: relative;
		width: 100%;
		height: 70vw;
		object-fit: cover;
		border-radius: 12px;
		/* overflow: hidden; */
		/* background-size: 100% 100%; */
	}

	.click_generate {

		/*路径*/

		/* left: 383px;
top: 843px; */
		margin-top: 5vw;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70%;
		height: 10vw;
		font: 100 12px/1.5 PingFang SC, sans-serif;
		color: rgba(255, 255, 255, 1);
		opacity: 1;
		background: rgb(158, 156, 156);
		border-radius: 20px;
		cursor: pointer;
		/* background-color: transparent; */
		border: none;
		cursor: pointer;
		/* border: orchid solid 1px; */

	}

	.input_type {
		width: 100%;
		height: auto;

	}

	.h2 {
		margin-top: 1rem;
	}

	.upload-img {
		width: 30%;
		height: auto;
		/* margin-top: 40vw */
	}

	.magnifier {

		position: relative;
		bottom: 10px;
		right: 10px;
		top: -40vw;
		cursor: pointer;
		z-index: 10000;
		left: 22vw;
		width: 15vw;
		height: 20vw;
	}

	.click_generate_upload {

		/*路径*/

		/* left: 383px;
top: 843px; */
		margin-top: 5vw;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70%;
		height: 10vw;
		font: 300 18px/1.5 PingFang SC, sans-serif;
		color: rgba(255, 255, 255, 1);
		opacity: 1;
		background: rgb(16, 127, 238);
		border-radius: 20px;
		cursor: pointer;
		/* background-color: transparent; */
		border: none;
		cursor: pointer;

	}


	.img {

		/* width: 90%;
height: 90%; */
		/* 可以根据需要调整高度 */
		/* background-image: url("https://img.mobiuspace.com/image/aigc/95698695c820af829243c7d0eeb4579d.webp"); */
		background-size: 80%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		max-width: 100px;
		/* 保持图片原始宽高比，覆盖整个容器 */
		/* border: #007bff solid 1px; */



		/* overflow: hidden; */

	}



	.select_type {
		width: 80%;
		height: 5rem;
		/* border: #976af2 solid 1px; */
		align-items: center;
		justify-content: center;
		padding: 10px 15px;
		margin-top: 20px;
		/* background-color: rgb(81, 79, 84); */
		display: flex;
		text-align: center;
		flex-direction: column;
		background: linear-gradient(135deg, #ffffff, #f8f8f8);
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		transition: background-color 0.3s, transform 0.2s;
		cursor: pointer;
		border-radius: 10px;
		/* transform: perspective(100px) rotateX(5deg); */
	}

	.select_type :hover {
		/* background: linear-gradient(135deg, #f0f0f0, #e0e0e0); */
		/* transform: translateY(-2px); */
	}

	.select_type .selected {
		background: linear-gradient(135deg, #333, #555);
		color: white;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

	}

	.select_type_box {
		width: 100%;
		height: 90%;
		margin-top: 0px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		flex-direction: column;
		background-color: rgb(190, 227, 244);
		border-radius: 10px;
	}

	.buton_select {
		width: 5rem;
		height: 2rem;
		display: flex;
		margin-left: 2px;
		margin-right: 2px;

		/* margin:auto; */
		justify-content: center;
		align-items: center;
		/* float: left; */
		font-size: 1rem;
		/* padding: 10px; */
		word-wrap: break-word;
		border-radius: 10px;
		background: linear-gradient(135deg, #8ec5fc, #e0c3fc);
		color: white;
		/* justify-content: center; */

		/* border: #d71559 solid 1px; */
	}

	.opotion_box {
		width: 100%;
		height: 10rem;
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: row;
		/* overflow: scroll; */
		/* overflow-x: scroll */
		/* transform: perspective(100px) rotateX(5deg); */

		/* margin: 20px; */
	}

	.type_name {
		width: 100%;
		font-weight: bold;
		height: 1.5rem;
		margin-bottom: 10px;
		text-transform: uppercase;
		color: #272424;
		font-size: 1em;
		letter-spacing: 1px;
		text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
		pointer-events: none;
		/* 禁止 hover 动效 */
		background: #d0d0d0;
		/* padding: 5px 10px; */
		border-radius: 5px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		/* transform: perspective(100px) rotateX(5deg); */
	}

	.gender_select {

		width: 10rem;
		height: 2rem;
		border: #007bff;
		display: flex;
		margin: 0.5rem;
		align-items: center;
		float: left;
		word-wrap: break-word;
		border-radius: 8%;
		color: #976af2;
		transition: color 0.3s ease, transform 0.3s ease;
		justify-content: center;

	}


	.upload_img {
		/* width: 60%;
/* height: 90%; */

		background-size: 50%;
		background-position: center;
		background-repeat: no-repeat;
		object-fit: cover;
		background-image: url("../assets/icon-upload.368442cb.png");
		background-color: transparent;
		border: none;
		cursor: pointer;
		/* border:#976af2 solid 1px; */

	}

	.upload_img:hover {
		color: #976af2;
		/* 悬浮时背景颜色变化 */
		transition: color 0.3s ease, transform 0.3s ease;
		transform: translateY(-5px);
		/* 悬浮时向上移动 */
		/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
		/* 悬浮时添加阴影 */
	}



	.tempalte_box {
		/*路径*/

		/* left: 1273px;
top: 97px; */
		position: relative;
		display: flex;
		margin-left: 10px;
		margin-top: 10px;
		margin-right: 10px;
		/* margin-bottom: 900; */
		width: 100%;
		height: auto;
		opacity: 1;
		/* border: #7610f2 solid 1px; */
		background: rgba(21, 14, 38, 1);
	}

	.swap_word1 {
		/*free face swap online*/

		/* left: 435px;
top: 241px; */
		margin-top: -5vw;
		width: 100%;
		height: 15vw;
		text-align: center;
		/* opacity: 1; */
		/* display: flex; */
		/* position: absolute; */
		/** 文本1 */
		font-size: 1.5em;
		font-weight: 300;
		letter-spacing: 0px;
		line-height: 2rem;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		/* border:#007bff solid 1px */
	}

	.swap_word2 {
		/*experience fun or real swaps: select sin*/

		/* left: 290px;
top: 329px;
width: 810px;
height: 29px; */
		/* position: absolute; */
		display: flex;
		justify-content: center;
		opacity: 1;
		/** 文本1 */
		width: 100%;
		text-align: center;
		margin-top: 1rem;
		display: flex;
		font-size: 1em;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 2rem;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		padding: 1px;
		/* border: #ef24c0 solid 1px; */
	}

	.font_name3 {
		/*face swap*/

		/* left: 462px;
top: 373px; */
		/* width: 118px;
height: 35px; */
		opacity: 1;
		position: relative;
		/** 文本1 */
		margin-top: px;
		margin-left: 15px;
		margin-right: 15px;
		display: flex;
		font-size: 18px;
		font-weight: 300;
		letter-spacing: 0px;
		line-height: 1.2rem;
		color: rgba(112, 112, 112, 1);
		text-align: right;
		vertical-align: top;
		/* border: #00ff40 solid 1px; */
		background-color: transparent;
		/* border: darkgoldenrod;
border-radius: 8%; */
		cursor: pointer;
		/* border: #976af2 solid 1px */
	}

	.font_name3.isActive::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 3px;
		background: linear-gradient(to right, #1f1f1f, #007bff);
		border-radius: 5px;
		bottom: -5px
	}

	.font3_box {
		/* border: #00ff40 solid 1px; */
		margin-top: 8px;
		margin-left: 30px;
		margin-bottom: 8px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		/* margin-top: 20px;
margin-left: 30px; */
		/* justify-content: space-between; */
		/* float:inline-start; */
		width: 90%;
		height: auto;
		/* border: #007bff solid 1px */
	}

	.template_img {
		position: relative;
		display: flex;
		gap: 5px;
		flex-wrap: wrap;
		/* border: #00ff40 solid 1px; */
		/* margin-bottom: 10px; */
	}

	.template_img img {
		position: relative;
		width: calc(50% - 20px);
		height: auto;
		justify-content: space-between;
		margin: 5px;
		/* margin-top: 50px; */
		/* margin-bottom: 10px; */
		/* width: 20%; */
		object-fit: cover;
		border-radius: 10px;
		/* 50%宽度减去间距 */
		box-sizing: border-box;
		align-items: center;

	}

	.selected-image {
		border-color: #b07ee2 solid 2px;
	}

	.template_type {

		display: flex;
		width: 100%;
		height: auto;
		position: relative;
		margin-top: 80px;
		display: flex;
		background: rgba(44, 39, 56, 1);
		/* align-items:center; */
		flex-direction: column;
		/* border: #ef24c0 solid 1px; */
	}

	.template_type_select {

		/*sporty*/

		/* left: 26px;
top: 5.5px;
width: 50px;
height: 23px;
opacity: 1; */
		/** 文本1 */
		display: flex;
		position: relative;
		margin: 5px;
		/* padding: auto; */
		text-align: center;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 23px;
		color: rgba(255, 255, 255, 1);
		/* border: none; */
		/* background: seashell; */
		vertical-align: top;
		background-color: #01080f;
		cursor: pointer;

		/*路径*/

		/* left: 0px;
top: 0px; */
		width: 102px;
		height: 35px;
		/* opacity: 1; */
		/* border: 1px solid rgba(72, 52, 212, 1); */
		background-color: transparent;
		border: none;
		cursor: pointer;

	}

	/* .template_type_select:hover { */
	/* background-color: rgb(246, 246, 248); */
	/* 悬停时背景颜色变为深蓝色 */
	/* } */


	.template_type_select.isActive {
		/*路径*/

		/* left: 0px;
top: 0px; */
		/* content: ''; */
		/* position: absolute; */
		/* left: 0; */
		/* width: 102px;
height: 35px; */
		/* opacity: 1; */
		background: rgba(104, 109, 224, 1);
		box-shadow: 0px 1px 2px rgba(20, 14, 62, 0.25);
		z-index: 2;
	}

	.upload_template {
		position: absolute;
		display: flex;
		margin-top: 20px;
		margin-bottom: 50px;
		margin-left: 20px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 90%;
		height: 30px;

		/*路径*/
		/* width: 506px; */
		/* height: 40px; */
		/* opacity: 1; */
		background: rgba(204, 204, 204, 0.3);

		/** 文本1 */
		font-size: 20px;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 38px;
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		/* border: #007bff solid 1px; */
		cursor: pointer;
		/* background-color: transparent; */
		border: none;
		/* cursor: pointer; */
	}

	.img_doc {
		width: auto;
		height: 100vw;
		display: flex;
		/* position: relative; */
		/* border: #00ff40 solid 1px; */
		/* align-items: center;
justify-content: center; */
		flex-direction: column;

	}

	.img_doc1_box {
		/*face-swap-and-demonstrate-the-ability-to-generate-various-different-faces-of-a-man 1*/
		display: flex;
		margin-top: 5vw;
		overflow: hidden;
		width: 100%;
		height: 300px;
	}


	.img_doc1_box_box {
		/*create infinite faces with al face swap technology*/

		/* left: 915px;
top: 1168px; */
		/* margin-top: 50%;
margin-left: 50%; */
		/* margin: 10px; */
		/* float: left; */

		display: flex;
		width: 100%;
		margin-top: 5vw;
		align-items: center;
		padding: auto;
		height: auto;
		opacity: 1;
		flex-direction: column;
		/* border: #007bff solid 1px; */
	}

	.img_doc1_box_doc1 {
		/*create infinite faces with al face swap technology*/

		/* left: 915px;
top: 1168px; */
		/* margin-top: 3vw;
margin-left: 3vw; */
		display: flex;
		width: 90%;
		height: auto;
		opacity: 1;
		padding: auto;
		/** 文本1 */
		font-size: 10px;
		font-weight: 200;
		letter-spacing: 0px;
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		text-wrap: wrap;
		/* border: #007bff solid 1px; */
	}

	.img_doc1_box_doc2 {

		/*ai face swap has surged in popularity as a dynamic photo trend. its advanced algorithms accurately detect and track
facial features, allowing users to effortlessly create realistic face swaps. Pook ai face swapper is one of the best
free online tools for face swapping, you can use it to seamlessly swap face in any photo and enjoy endless fun while
engaging with realistic face swap photos.*/


		width: 90%;
		height: auto;
		opacity: 1;
		display: flex;
		/** 文本1 */
		font-size: 13px;
		font-weight: 200;
		letter-spacing: 0px;
		/* line-height: 12px; */
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		text-wrap: wrap;
		/* border: #f462c8 solid 1px; */
	}

	.swap_boten {
		display: flex;
		margin-left: 10vw;
		width: 200px;
		height: 92px;
		opacity: 1;
		background: rgba(72, 52, 212, 1);
		/* border: #00ff40 solid 1px; */
		cursor: pointer;
		border: none;
	}

	.swap_boten.text {

		width: 178px;
		height: 38px;
		opacity: 1;
		/** 文本1 */
		font-size: 20px;
		font-weight: 300;
		align-items: center;
		justify-content: center;
		letter-spacing: 0px;
		line-height: 38px;
		color: rgba(255, 255, 255, 1);
		text-align: center;
		/* vertical-align: top; */
	}

	.doc2_box {
		/* left: 500px;
top: 1886px; */
		/* margin-left: 5vw; */

		display: flex;
		align-items: center;
		justify-content: center;
		/* margin-top: 2vw; */
		margin: 2vw auto;
		width: 90%;
		height: 160px;
		opacity: 1;
		font-size: 1.5em;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: 30px;
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		text-align: center;
		/* 文字向左对齐 */
		word-wrap: break-word;
		/* 允许长单词或 URL 地址换行到下一行 */
		/* border: #7610f2 solid 1px;; */
	}

	.row_doc_box_base {

		display: flex;
		/* position: relative; */
		float: left;
		margin-top: 10px;
		margin: 0px auto;
		width: auto;
		height: auto;
		padding: 10px;
		/* opacity: 1; */
		flex-direction: column;
		/* overflow: hidden; */
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		/* 允许换行 */
		/* justify-content: space-between; */
		/* 项目之间的间距均匀分布 */
		/* border: #00ff40 solid 1px; */
		/* overflow: scroll; */
		/* border:#976af2 solid 1px; */
	}

	.col_doc_box_base {

		display: flex;
		position: relative;
		float: left;
		margin-top: 10px;
		width: auto;
		height: 40vw;
		opacity: 1;
		flex-direction: column;
		/* overflow: hidden; */
		/* flex-wrap: wrap; */
		/* 允许换行 */
		/* justify-content: space-between; */
		/* 项目之间的间距均匀分布 */
		/* border: #00ff40 solid 1px; */
		overflow: hidden;
	}

	/* 第一块图文 */
	.doc3_box_1 {

		/*rectangle 13*/

		/* display: flex;
position: relative; */
		/* margin-top: 10px; */
		/* margin-left: 5vw; */
		margin: 10px auto;
		/* left: 0px;
top: 100px; */
		width: 100%;
		height: 40%;
		/* opacity: 1; */
		/* background: rgba(40, 25, 65, 1); */
	}

	.doc3_box_1_image {
		max-width: 80%;
		/* 图片最大宽度为容器的100% */
		max-height: auto;
		/* 图片最大高度为容器的100% */
		display: block;
		/* 将图片转换为块级元素 */
		margin: 0 auto;
		/* 水平居中 */
		object-fit: cover;
		overflow: hidden;
	}

	.doc3_box_1_text_box {

		opacity: 1;
		border-radius: 10px;
		background: rgba(255, 255, 255, 1);
		height: 38%;
	}


	.doc3_box_1_text {


		/*ai face swap has surged in popularity*/

		/* left: 83px;
top: 2399px;
width: 474px;
height: 40px;
opacity: 1; */
		/** 文本1 */

		font-size: 1.4em;
		font-weight: 350;
		letter-spacing: 0px;
		line-height: auto;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		/* width: 90%; */
		/* 文本最大宽度为容器的100% */
		/* display: block; */
		/* 将文本转换为块级元素 */
		/* margin: 0 auto; */
		/* 水平居中 */
		/* text-align: left; */
		/* 文字向左对齐 */
		word-wrap: break-word;
		/* 允许长单词或 URL 地址换行到下一行 */
		/* font-size: 24px; */
		/* color: #f5f2f4; */
		/* border: #ff0055 solid 1px;; */
	}


	.doc3_box_1_text2 {

		/** 文本1 */
		font-size: 1em;
		font-weight: 00;
		letter-spacing: 0px;
		line-height: auto;
		color: rgba(0, 0, 0, 1);
		vertical-align: top;
		word-wrap: break-word;

	}

	.swap_button_style {
		width: 8em;
		height: 2em;
		opacity: 1;
		font-size: 1.3em;
		font-weight: 100;
		color: #f5f2f4;
		background: rgba(72, 52, 212, 1);
		/* border: #00ff40 solid 1px; */
		cursor: pointer;
		border-radius: 15px;
		border: none;
	}

	.box_center {
		display: flex;
		margin: 0 auto;
		justify-content: center;
		align-items: center;
	}

	/* 第3块图文 */
	.box_text_base {

		margin-left: 2vw;
		font-size: 1.4em;
		font-weight: 350;
		letter-spacing: 0px;
		line-height: auto;
		color: rgb(250, 247, 247);
		vertical-align: top;
		width: 90%;
		align-content: center;
		/* 文本最大宽度为容器的100% */
		/* display: block; */
		/* 将文本转换为块级元素 */
		/* margin: 0 auto; */
		/* 水平居中 */
		text-align: left;
		/* 文字向左对齐 */
		word-wrap: break-word;

	}


	.box_text_base2 {

		/** 文本1 */
		width: 100%;
		height: auto;
		margin-top: 2vw;
		margin-left: 5vw;
		font-size: 1em;
		font-weight: 200;
		letter-spacing: 0px;
		line-height: auto;
		color: rgb(248, 245, 245);
		vertical-align: top;
		/* word-wrap:break-word; */
		text-wrap: wrap;
		overflow-wrap: break-word;
		/* 允许在单词内换行 */
		word-wrap: break-word;
		/* 兼容旧版浏览器 */
		overflow: auto;
		/* 如果内容超出容器大小，显示滚动条 */
		padding: 10px;

	}

	.box_img_base {

		/* max-width: 50%; */
		/* 图片最大宽度为容器的100% */
		float: left;
		max-width: 90%;
		max-height: auto;
		/* 图片最大高度为容器的100% */
		display: block;
		/* 将图片转换为块级元素 */
		margin: 0 auto;
		/* 水平居中 */
		object-fit: cover;
	}

	.fdvfd {
		/*rectangle 14*/

		/* left: 1104px;
top: 3571px; */
		/* margin-top: 7vw; */
		margin: 10px auto;
		width: 90%;
		height: auto;
		opacity: 1;
		background: rgba(217, 217, 217, 0);
		/* overflow: hidden; */
		/* object-fit:auto; */
	}


	.buy_back_box {
		/*rectangle 23*/

		/* left: 109px;
top: 8298px; */
		/* display: flex;
position: absolute; */
		width: 90%;
		height: auto;
		opacity: 1;
		border-radius: 8px;
		/* background: rgba(255, 255, 255, 1); */
		/* border: #007bff solid 1px; */
	}

	.buy_back_box_text {
		/*free and quaility face swaps*/

		/* left: 119px;
top: 8322px; */
		/* width: 470px;
height: 46px; */
		display: flex;
		justify-items: center;
		align-items: center;
		margin: 2px auto;
		/* margin-left: 10px; */
		width: 100%;
		height: 60px;
		text-align: center;
		/** 文本1 */
		font-size: 1.5rem;
		font-weight: 400;
		letter-spacing: 0px;
		line-height: auto;
		color: rgb(11, 11, 11);
		vertical-align: top;
		background-color: #f5f2f4;
		border-radius: 12px;
		/* border: #ef24c0 solid 1px; */

	}

	.buy_back_box_text2 {
		/*free and quaility face swaps*/
		margin: 2px auto;
		padding: 10px;
		/** 文本1 */
		width: 100%;
		height: auto;
		font-size: 1rem;
		font-weight: 300;
		text-wrap: wrap;
		letter-spacing: 0px;
		line-height: auto;
		color: rgb(252, 250, 250);
		vertical-align: top;
		border-radius: 12px;
		/* border: #ef24c0 solid 1px; */
		overflow: scroll;

	}

	.stars {
		/*star 1*/
		/* position: absolute; */

		margin: 1vw auto;
		/* border: #00ff40 solid 1px; */
		/* width: auto; */
		/* height: 30px; */
		/* display: flex; */
		text-align: center;
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		/* object-fit: cover; */
	}

	.faq {

		/*1.can i face swap pnline for free*/
		display: flex;
		align-items: center;
		justify-content: left;
		margin: 5vw auto;
		width: 80%;
		height: 20px;
		opacity: 1;
		/** 文本1 */
		font-size: 20px;
		font-weight: 200;
		letter-spacing: 0px;
		line-height: 20px;
		color: rgba(255, 255, 255, 1);
		vertical-align: top;
		/* border: #a694a0 solid 1px; */
	}

	.conect_us {

		display: flex;
		align-items: center;
		justify-content: left;
		/* margin: 30px auto; */
		flex-direction: row;
		color: #f5f2f4;
		letter-spacing: 2px;
		word-spacing: 2px;
		border-top: #3e3f3e solid 2px;

	}

	.connect_us_box {

		/* height: 500px; */

		display: flex;
		margin-top: 10px;
		/* margin: 10px; */
		font-size: 10px;
		font-weight: 200;
		letter-spacing: 0px;
		line-height: 40px;
		align-items: center;
		justify-content: left;
		/* margin: 30px auto; */
		flex-direction: row;
		color: #f5f2f4;
		letter-spacing: 2px;
		word-spacing: 2px;
		/* border-top: #3e3f3e solid 2px; */
	}
}
</style>